/** @jsx jsx */
import { jsx } from 'theme-ui';

import React, { useEffect } from 'react';
import { Flex, Box, Heading, Text, Link } from 'theme-ui';
import {
  AiOutlineInstagram,
  AiOutlineFacebook,
  AiFillYoutube,
} from 'react-icons/ai';
import { FaPinterest } from 'react-icons/fa';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Trans, t } from '@lingui/macro';
import useLocalStorage from 'react-use/lib/useLocalStorage';

import Breadcrumbs from '../../../plugins/storefront/src/components/Breadcrumbs';
import { useShopifyFunctions } from '../../../plugins/storefront/src/hooks/useShopifyFunctions';

const persistedStateId = 'shopifyCheckout';

const OrderSuccessPage = (props) => {
  const { storeName } = props.data.site.siteMetadata.gatsbyStorefrontConfig;
  const [shopifyCheckoutId] = useLocalStorage(persistedStateId, '');
  const { resetCart } = useShopifyFunctions();

  useEffect(() => {
    if (shopifyCheckoutId && shopifyCheckoutId.length > 0) {
      resetCart();
    }
  }, [shopifyCheckoutId]);

  return (
    <>
      <Helmet
        title={t({
          id: `OrderSuccess.Title`,
          message: `Order placed successfully`,
        })}
        titleTemplate={t`%s — ${storeName}`}
        defer={false}
      >
        <meta
          name="description"
          content={t({
            id: `OrderSuccess.MetaDescription`,
            message: `Your order is successfully placed`,
          })}
        />
      </Helmet>
      <Flex
        my={3}
        py={[2, 3, 4]}
        sx={{
          width: '100%',
          bg: '#f4f1eb',
          justifyContent: 'center',
        }}
      >
        <Box mx={3}>
          <Breadcrumbs
            productTitle={t({
              id: `OrderSuccess.BreadcrumbsTitle`,
              message: `Order placed successfully`,
            })}
            separator="/"
          />
        </Box>
      </Flex>

      <Box
        mx="auto"
        px={[3, 2]}
        mt={[1, 4]}
        mb={4}
        sx={{
          width: '100%',
          maxWidth: 1300,
        }}
      >
        <Heading as="h1" sx={{ fontSize: [4, 5] }}>
          <Trans id="OrderSuccess.Heading">
            Your Order is successfully placed
          </Trans>
        </Heading>

        <Text as="p" mt={[2, 3]}>
          <Trans id="OrderSuccess.Description">
            Thank you for your order! We will contact you shortly.
          </Trans>
        </Text>

        <Text as="p" mt={[2, 3]}>
          <Trans id="Thankyou.DescriptionLine2">
            Meanwhile, please take some time to find inspiration on our social
            media channels:
          </Trans>
        </Text>
        <Box>
          <Link
            href="https://instagram.com/thekisscurls"
            mr={2}
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <AiOutlineInstagram size="28" />
          </Link>
          <Link
            href="https://facebook.com/kisscurls"
            mr={2}
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <AiOutlineFacebook size="28" />
          </Link>
          <Link
            href="https://www.youtube.com/channel/UCE8i9ANB0-0p75yFNrKA1oA"
            mr={2}
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <AiFillYoutube size="28" />
          </Link>
          <Link
            href="https://www.pinterest.com/thekisscurls"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <FaPinterest size="28" />
          </Link>
        </Box>
        <Text as="p" mt={[2, 3]}>
          <Trans id="Thankyou.DescriptionFollowUs">
            Don&apos;t forget to follow if you like us 😉
          </Trans>
        </Text>
      </Box>
    </>
  );
};

export const OrderSuccessQuery = graphql`
  query {
    site {
      siteMetadata {
        gatsbyStorefrontConfig {
          storeName
        }
      }
    }
  }
`;

export default OrderSuccessPage;
